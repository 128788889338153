import React from 'react'
import ReactDOM from 'react-dom/client'
// import ReactDOM from 'react-dom'
import App from './App'

// React 17 -> 18
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	//Remove 'React.StrictMode' in Production
	<React.StrictMode>
		<App />
	</React.StrictMode>
	// ,
	// document.getElementById('root')
)
