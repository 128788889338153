import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, useMediaQuery, Typography, Button } from '@mui/material'
import { Tab, TabContent } from '@mikeint0sh/the-keeton-cloud-components/components'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { AccountsContext } from '../AccountsContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'AccountSummary'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	title: `${PREFIX}-title`,
	button: `${PREFIX}-button`,
	content: `${PREFIX}-content`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.card}`]: {
		padding: 10,
		paddingRight: 0
	},
	[`& .${classes.grid}`]: {
		marginTop: 10,
		marginBottom: 10
	},
	[`& .${classes.title}`]: {
		paddingLeft: 5,
		paddingBottom: 10,
		textAlign: 'center'
	},
	[`& .${classes.button}`]: {
		width: '100%',
		padding: 0,
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	}
}))

const AccountSummary = () => {
	const { accounts } = useContext(GlobalContext)
	const { accountState, setAccountState } = useContext(AccountsContext)
	const isXs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'), { defaultMatches: true })

	const getTabValue = (tab, indices) => {
		if (indices.vIndex) {
			//Virtual Account
			return accounts[indices.pIndex][indices.vIndex][tab]
		} else {
			//Physical Account
			return accounts[indices.pIndex][0][tab]
		}
	}

	const handleTransfer = () => {
		//Trigger AddAccountPopup with the current V-Acc.'s ID and current location
		setAccountState({
			...accountState,
			showAccountPopup: true
		})
	}

	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				<Grid container direction='row' className={classes.grid}>
					<Tab divider>
						<TabContent
							title='Current Balance'
							content={
								getTabValue('balance', accountState.activeAccount) !== undefined
									? accounting.format(getTabValue('balance', accountState.activeAccount))
									: undefined
							}
						/>
					</Tab>

					<Tab divider={!isXs}>
						<TabContent
							title='Total In'
							content={
								accountState.accountSummary.in !== undefined ? accounting.format(accountState.accountSummary.in) : undefined
							}
						/>
					</Tab>

					<Tab divider margin={isXs}>
						<TabContent
							title='Total Out'
							content={
								accountState.accountSummary.out !== undefined ? accounting.format(accountState.accountSummary.out) : undefined
							}
						/>
					</Tab>

					<Tab margin={isXs}>
						<Grid item xs={12} sx={{ width: '100%', padding: 0, margin: 0 }}>
							<Button onClick={handleTransfer} className={classes.button}>
								<Grid container direction='column' sx={{ width: '100%' }}>
									<Grid item>
										<Typography variant='subtitle1' className={classes.title} sx={{ padding: 0 }}>
											Account Transfer
										</Typography>
									</Grid>
									<Grid item sx={{ flex: 1 }}></Grid>
									<Grid item>
										<Typography variant='h2' className={classes.title} sx={{ paddingTop: '10px' }}>
											+
										</Typography>
									</Grid>
								</Grid>
							</Button>
						</Grid>
					</Tab>
				</Grid>
			</Card>
		</Root>
	)
}

export default AccountSummary
