import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, Button, Card, useTheme } from '@mui/material'
import { PieChart } from './'

const PREFIX = 'BudgetTile'
const classes = {
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`,
	root: `${PREFIX}-root`,
	scoreFont: `${PREFIX}-scoreFont`
}

const CardStyled = styled(Card)(({ theme: { breakpoints } }) => ({
	[`&.${classes.card}`]: {
		paddingRight: 10,
		paddingLeft: 10,
		paddingTop: 15,
		borderRadius: 16, //25
		[breakpoints.up('xs')]: {
			height: 145,
			width: 145
		},
		[breakpoints.up('sm')]: {
			height: 120,
			width: 120
		},
		[breakpoints.up('md')]: {
			height: 130,
			width: 130
		},
		[breakpoints.up('lg')]: {
			height: 220,
			width: 220
		},
		[breakpoints.up('xl')]: {
			height: 300,
			width: 300
		}
	},
	[`& .${classes.title}`]: {
		paddingLeft: 5,
		paddingBottom: 1,
		textAlign: 'center'
	}
}))

const BudgetTile = (props) => {
	const { palette } = useTheme()

	return (
		<Button onClick={props.changeActiveView} sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
			<Grid container justifyContent='center' alignItems='center'>
				<Grid item>
					<CardStyled
						className={classes.card}
						sx={
							props.active
								? {
										backgroundImage: `radial-gradient(circle, ${palette.mode === 'dark' ? '#333333' : '#FFF'}, ${
											palette.mode === 'dark' ? palette.background : '#dddddf'
										})`
								  }
								: {}
						}
					>
						<Grid container direction='column' justifyContent='center' sx={{ height: '100%' }}>
							<Grid item>
								<Typography variant='body1' className={classes.title}>
									{props.title}
								</Typography>
							</Grid>
							<Grid item container direction='row' alignItems='center' justifyContent='center' sx={{ flex: 1 }}>
								<PieChart tab={props.tab} />
							</Grid>
						</Grid>
					</CardStyled>
				</Grid>
			</Grid>
		</Button>
	)
}

export default BudgetTile
